<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Цвета</div>
      </template>

      <div class="color-box__wrap">
        <div v-for="item in colors" :key="item.id" class="color-box">
          <div class="color-box__color" :style="{ background: item.hex }" />
          <h6>{{ item.variable }}</h6>
          <p class="secondary">{{ item.hex }}</p>
        </div>
      </div>
    </UISection>
  </div>
</template>

<script>
import { colors } from '@/views/ui/data/ui'
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Colors',
  components: { UISection },
  data() {
    return { colors }
  },
}
</script>

<style lang="sass">
.color-box
  width: 180px
  background: #FFFFFF
  border: 1px solid #DCDCDC
  padding: 8px
  border-radius: 8px

  &__wrap
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    gap: 10px

  &__color
    width: 100%
    padding-top: 100%
    background: darkred
    border-radius: 8px
    margin-bottom: 8px
    border: 1px solid #DCDCDC
</style>
