export const contentUI = [
  { id: 1, title: 'Цвета', component: 'Colors' },
  { id: 2, title: 'Кнопки', component: 'Buttons' },
  { id: 6, title: 'Колапсы', component: 'Collapse' },
  { id: 7, title: 'Диалоги', component: 'Dialogs' },
  { id: 8, title: 'Дропдаун', component: 'Dropdowns' },
  { id: 3, title: 'Заголовки', component: 'Headers' },
  { id: 4, title: 'Параграфы', component: 'Paragraph' },
  { id: 10, title: 'Списки', component: 'Lists' },
  { id: 5, title: 'Инпуты', component: 'Inputs' },
  { id: 9, title: 'Таблицы', component: 'Tables' },
]

export const colors = [
  { id: 1, variable: '$btn-orange', hex: '#DB6D39' },
  { id: 2, variable: '$color-white', hex: '#FFFFFF' },
  { id: 3, variable: '$color-dark-gray', hex: '#837F7F' },
  { id: 4, variable: '$color-gray', hex: '#515151' },
  { id: 5, variable: '$color-light-gray', hex: '#eeeeee' },
  { id: 6, variable: '$color-btn-gray', hex: '#AAAAAA' },
  { id: 7, variable: '$color-blue', hex: '#0C97C7' },
  { id: 8, variable: '$color-blue-light', hex: '#4CA7D8' },
  { id: 9, variable: '$color-blue-light-2', hex: '#50B1DB' },
  { id: 10, variable: '$color-black', hex: '#000000' },
  { id: 11, variable: '$color-green', hex: '#5DAF34' },
  { id: 12, variable: '$color-red', hex: '#F56C6C' },
  { id: 13, variable: '$color-control-primary', hex: '#2E3B52' },
  { id: 14, variable: '$color-control-secondary', hex: '#606F89' },
]
