var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Цвета")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "color-box__wrap" },
            _vm._l(_vm.colors, function (item) {
              return _c("div", { key: item.id, staticClass: "color-box" }, [
                _c("div", {
                  staticClass: "color-box__color",
                  style: { background: item.hex },
                }),
                _c("h6", [_vm._v(_vm._s(item.variable))]),
                _c("p", { staticClass: "secondary" }, [
                  _vm._v(_vm._s(item.hex)),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }